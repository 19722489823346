import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Modal, Spin } from 'antd';
import FormCreateOrder from './components/FormCreateOrder';
import { getListStore } from '../../../Stores/scenes/List/actions';
import {
  checkVariantRiskAction,
  createOrder,
  closeVariantRiskAction,
  resetReducerAction,
} from './actions';
import { listCountry } from '../../../Users/scenes/Profile/actions';
import { getListExproduct } from '../../../Mockups/scenes/Create/actions';
import { Wrapper } from './styled';
import { GMButton } from 'components/Button';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: null,
      checked: false,
      showModalTax: false,
      isLoading1: false
    };
  }

  onChange = (e) => {
    this.setState({
      checked: e.target.checked,
    });
  };

  componentDidMount() {
    this.props.getListStore({
      isNotPlatform: 1,
      sortBy: 'id',
      sortType: 'asc',
    });
    this.props.getListExproduct({ sortBy: 'id', sortType: 'asc' });
    this.props.listCountry();
  }

  getConvertedData = () => {
    let values = this.props.FormCreateOrderValues;
    const listProductId = [];
    const listColorId = [];
    const listSizeId = [];
    const listSidePrint = [];
    const listDesignFront = [];
    const listDesignBack = [];
    const listQuantity = [];
    if (values.listOrderItems && Array.isArray(values.listOrderItems)) {
      for (let i = 0; i < values.listOrderItems.length; i++) {
        listProductId.push(values.listOrderItems?.[i]?.['productId'] || 0);
        listDesignFront.push(
          values.listOrderItems?.[i]?.['designFrontId'] || ''
        );
        listDesignBack.push(values.listOrderItems?.[i]?.['designBackId'] || '');
        listColorId.push(values.listOrderItems?.[i]?.['colorId'] || 0);
        listSizeId.push(values.listOrderItems?.[i]?.['sizeId'] || 0);
        listQuantity.push(values.listOrderItems?.[i]?.['quantity'] || 1);
        listSidePrint.push(
          values.listOrderItems?.[i]?.['sidePrint'] || 'normal'
        );
        // listSidePrint.push(values.listOrderItems?.[i]?.['type'] || 0);
      }
    }
    const shippingMethod =
      values.shippingMethod === 'null' ? null : values.shippingMethod;
    const dataToCreate = {
      storeId: values.storeId || '0',
      name: values.fullName || '',
      phone: values.phoneNumber || '',
      address1: values.address01 || '',
      address2: values.address02 || '',
      city: values.city || '',
      zipcode: values.zipcode || '',
      orderValue: values.orderValue || '',
      taxNumber: values.taxNumber || '',
      orderTaxValue: values.orderTaxValue || '',
      iossNumber: values.iossNumber || '',
      // shippingMethod: values.shippingMethod,
      shippingMethod,
      onlineId: values.orderId || '',
      email: values.email || '',
      state: values.state || '',
      country: values.country || '',
      chooseProduct: listProductId,
      chooseColor: listColorId,
      chooseSize: listSizeId,
      chooseQuantity: listQuantity,
      chooseSidePrint: listSidePrint,
      chooseDesignFront: listDesignFront,
      chooseDesignBack: listDesignBack,
      orderNote: values.orderNote || '',
      skipOrder: values.skipOrder?.[0] || 0,
    };
    return dataToCreate;
  };
  handleSubmit = (values) => {
    let data = [];
    let newArray = values?.listOrderItems?.map(function (i) {
      return data.push({
        exp_id: parseInt(i?.productId),
        color_id: parseInt(i?.colorId),
        size_id: parseInt(i?.sizeId),
      });
    });
    this.props.checkVariantRiskAction({
      data: { data: data },
      submitData: this.getConvertedData(),
      form: 'create_order',
    });

    if(values.country === "142" || values.country === "43"){
      if(!values.taxNumber || typeof values.taxNumber == "undefined"){
        this.setState({ showModalTax: true, isLoading1: false });
      }else{
        this.setState({ showModalTax: false, isLoading1: false });
      }
    }else{
      this.setState({ showModalTax: false, isLoading1: false });
    }
  };
  handleOnCreateOrder = () => {
    window.scroll(0, 0);
    this.props.closeVariantRiskAction();
    this.setState({ showModalTax: false, isLoading1: false });
    this.props.createOrder(this.getConvertedData());
    this.props.resetReducerAction();
  };
  handleCloseModal = () => {
    this.props.closeVariantRiskAction();
    this.setState({ showModalTax: false, isLoading1: false });
  };

  render() {
    const {
      pageName,
      createOrderLoading,
      stores,
      countries,
      exproducts,
      profile,
      listVariantRisk,
      listVariantRiskLoading,
      showModalVariant,
    } = this.props;
    const { showModalTax, isLoading1 } = this.state;

    const tax_number_warning = {
      color: 'red',
    };

    const initialValues = {
      storeId: '0',
      country: '231',
      countryCode: '',
      shippingMethod: '',
      fullName: '',
      orderId: '',
      orderNote: '',
      zipcode: '',
      state: '',
      city: '',
      address02: '',
      address01: '',
      phoneNumber: '',
      email: '',
      listOrderItems: [
        {
          key: 0,
          productId: '0',
          colorId: '0',
          sizeId: '0',
          quantity: 1,
          price: 0,
          designFront: '',
          designBack: '',
          mockup: '',
          designFrontId: '',
          designBackId: '',
          sidePrint: 'normal',
          printLocation: 'front,back',
          typeProduct: 0,
          type: 0,
        },
      ],
    };

    return (
      <>
        <Wrapper pageName={pageName}>
          <div>
            <Spin
              spinning={
                createOrderLoading ||
                stores.loading ||
                countries.loading ||
                exproducts.loading ||
                listVariantRiskLoading ||
                isLoading1
              }
            >
              <FormCreateOrder
                profile={profile}
                history={this.props.history}
                exproducts={exproducts.data}
                stores={stores.data}
                countries={countries.data}
                onSubmit={this.handleSubmit}
                initialValues={initialValues}
              />
            </Spin>
          </div>
        </Wrapper>
        <Modal
          centered
          title="Order confirmation required"
          visible={showModalVariant === true || showModalTax === true}
          onCancel={() => {
            this.handleCloseModal();
          }}
          onOk={() => {
            this.handleOnCreateOrder();
          }}
          width={800}
          footer={null}
        >

          { showModalTax === true ? (
              <div style={tax_number_warning}>
                Your order is missing Tax number. Please update the Tax number for Mexico recipient to prevent delivery interrupted.
              </div>
          ) : ''
          }

          {showModalVariant === true ? (<Spin spinning={listVariantRiskLoading}>
            <div className="mb-2">
              Your order includes the following{' '}
              <a
                  href="https://help.gearment.com/en/article/order-with-variation-at-risk-8wumdy/"
                  target="blank"
              >
                variant(s) at risk
              </a>
              :
            </div>
            {listVariantRisk?.data?.map((i) => {
              return (
                  <p>
                    <ul>
                      <li>
                        {i?.exp_brand} - {i?.color_name} - {i?.size_name}
                      </li>
                    </ul>
                  </p>
              );
            })}
            <div>
              By continuing, you confirm to create the order with the variant(s)
              at risk and acknowledge that the processing time may be extended
              for a few business days.
            </div>
          </Spin>) : ''}

          <div className="d-flex mt-3 mb-3 pb-3 align-items-right justify-content-end">
            <GMButton
              color="SecondaryGray"
              type="button"
              onClick={() => {
                this.handleCloseModal();
              }}
              style={{ width: 170 }}
            >
              Cancel
            </GMButton>
            <Spin spinning={createOrderLoading}>
              {' '}
              <GMButton
                style={{ width: 170 }}
                onClick={() => {
                  this.handleOnCreateOrder();
                }}
                className="ml-3"
              >
                Confirm order
              </GMButton>
            </Spin>
          </div>
        </Modal>
      </>
    );
  }
}

export default connect(
  (state) => ({
    createOrderLoading: state.order.createOrder.createOrder.loading,
    stores: state.store.list.stores2,
    countries: state.user.profile.listCountry,
    FormCreateOrderValues: state.form.FormCreateOrder?.values,
    exproducts: state.mockup.create.exproducts,
    profile: state.auth.info.profile,
    listVariantRiskLoading: state.order.createOrder.listVariantRisk.loading,
    listVariantRisk: state.order.createOrder.listVariantRisk,
    showModalVariant: state.order.createOrder.showModalVariant,
  }),
  (dispatch) => ({
    createOrder: (data) => {
      dispatch(createOrder(data));
    },
    getListStore: (options) => {
      dispatch(getListStore(options));
    },
    listCountry: () => {
      dispatch(listCountry());
    },
    getListExproduct: (params) => {
      dispatch(getListExproduct(params));
    },
    checkVariantRiskAction: (data) => {
      dispatch(checkVariantRiskAction(data));
    },
    closeVariantRiskAction: () => {
      dispatch(closeVariantRiskAction());
    },
    resetReducerAction: () => {
      dispatch(resetReducerAction());
    },
  })
)(index);
