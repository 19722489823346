import { GMButton } from "components/Button";
import { GMModal } from "components/Modal/styled.modal";
import React, { memo } from "react";
import { useState } from "react";
import { useEffect } from "react";
export default memo(({ redirectImportPendingDetail, history }) => {
  const [url, setUrl] = useState();
  const { location, replace } = history || {};
  useEffect(() => {
    const { redirectUrl, ...state2Send } = location?.state || {};
    setUrl(redirectUrl);
    if (location.state?.type === "error") {
      if (location.state?.file) {
        setUrl(location.state?.file);
      } else {
        setUrl("?");
      }
    }
  }, []);
  if (!(url || redirectImportPendingDetail?.redirectUrl)) {
    return null;
  }
  if (location.state?.type === "error") {
    return (
      <GMModal
        footer={null}
        visible
        onCancel={() => setUrl(undefined)}
        title={<b>Import error</b>}
        className="error-modal"
        // closable={false}
      >
        {location.state?.file ? (
          <p>
            Import file failed. Please download and adjust data at grey text
            cells (in the red rows). Then, upload and try again.{" "}
            <a href={url} target="_blank">
              Download file
            </a>
          </p>
        ) : (
          <p>{location.state?.clientMsg}</p>
        )}
        <div className="ctas">
          <GMButton className="btn" onClick={() => setUrl(undefined)}>
            Close
          </GMButton>
        </div>
      </GMModal>
    );
  }
  return (
    <GMModal
      footer={null}
      visible
      onCancel={() => setUrl(undefined)}
      title={<b>Your import is on its way!</b>}
      className="warning-modal"
      // closable={false}
    >
      <p>
        We’re currently importing your order into your store. This should take
        less than 15 minutes. You can follow the process{" "}
        <a
          href={url || redirectImportPendingDetail?.redirectUrl}
          target="_blank"
        >
          here
        </a>
      </p>

      {location.state?.warning ? (<p style={{color:'red'}}>Your order is missing Tax number. Please update the Tax number for Mexico recipient to prevent delivery interrupted.</p>) : ''}

      <div className="ctas">
        <GMButton className="btn" onClick={() => setUrl(undefined)}>
          Close
        </GMButton>
      </div>
    </GMModal>
  );
});
