import List from "./scenes/List";
import Edit from "./scenes/Edit";
import Create from "./scenes/Create";

export default [
  {
    key: "webhook",
    name: "Webhooks",
    component: List,
    path: "/settings/webhooks/list",
    icon: null,
    template: "default",
    isPublic: true
  },
  // {
  //   key: "webhook",
  //   name: "Create webhook",
  //   component: Create,
  //   path: "/settings/webhooks/create",
  //   icon: null,
  //   template: "default",
  //   isPublic: true
  // },
  // {
  //   key: "webhook.detail",
  //   name: "Edit webhook",
  //   component: Edit,
  //   path: "/settings/webhooks/:id/edit",
  //   icon: null,
  //   template: "default",
  //   isPublic: true
  // },
];
