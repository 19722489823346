import React, { useEffect, useState } from "react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { Tooltip } from 'antd';
import validate from "./validate";
import { label_order_template, list_file_type_to_import } from "utils/options";
import { download, getStoreTypeName } from "utils/helper";
import { listValidImportFile } from "utils/options";
import { WS_API } from "web.config";
// import RenderModalCreateStore from "utils/Form/RenderModalCreateStore";
// import { renderImportFile, renderSelectAnt } from "utils/Form";
import { renderRadioAnt, renderSelectAnt } from "utils/Form";
import renderImportFile from "./renderImportFile";
import { DropdownItemWrapper } from "../../styled";
import { ReactComponent as UploadIcon } from "images/icons/download-cloud.svg";
import { useCallback } from "react";
import { GMButton } from "components/Button";
import { GRAY_600 } from "components/colors";
import { checkWishAvailabe } from "apis/accounts";
import { enableLabelUrl } from "apis/general_settings";
import { ReactComponent as Plus } from 'images/icons/plus.svg';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

let Index = ({
  className,
  handleSubmit,
  stores = [],
  storeId = 0,
  typeFile = '-1',
  is_mobile = false,
  isUploading = false,
  listStoreToImportOrder = null,
  uploadProgress = 0,
  setUploadProgress = () => { },
  orderType,
}) => {
  const profile = useSelector((state) => state?.auth?.info?.profile);
  const history = useHistory();
  const uploadFileRef = React.createRef();
  const [listFile, setListFile] = useState([]);
  const [types2Import, setTypes2Import] = useState(list_file_type_to_import);
  const dispatch = useDispatch();
  const checkWish = async () => {
    const rs = await checkWishAvailabe();
    if (rs?.data?.check === false) {
      setTypes2Import(types => {
        return types.filter(item => item.name !== "Wish");
      });
    }
  };
  useEffect(() => {
    checkWish();
  }, []);

  const [LabelUrl, setLabelUrl] = useState(null);
  const fetchConfig = async () => {
    try {
      const response = await enableLabelUrl(); // Gọi API
      setLabelUrl(parseInt(response.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  useEffect(() => {
    if (orderType + '' === 2 + '') {
      dispatch(change('FormImportOrder', 'typeFile', 17));
    } else {
      dispatch(change('FormImportOrder', 'typeFile', '0'));
    }
  }, [orderType]);
  const getTypeList = useCallback(() => {
    const rs = types2Import.map(iItem => {
      return ({
        value: iItem.value + '',
        label: (
          <DropdownItemWrapper>
            <img src={iItem.logoSmall || iItem.logo} alt={iItem.name} /> {iItem.name}
          </DropdownItemWrapper>
        ),
      });
    })
    return rs;
  }, [types2Import]);
  const downloadSample = (evt) => {
    evt.preventDefault();
    // label_order_template
    if (orderType === '2') {
      download(label_order_template);
      return false;
    }
    const activeFileType = list_file_type_to_import.find(t => `${t.value || ''}` === `${typeFile || ''}`);
    if (!activeFileType) {
      return false;
    }
    download(activeFileType?.sampleLink);

    return false;
  };
  return (
    <div className={className}>
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label className="col-form-label font-weight-bold">Store</label>
          <div className="add-form">
            <Field
              name="storeId"
              component={renderSelectAnt}
              colSpan={24}
              options={[
                { value: "0", label: "- Select store -" },
                ...stores.map((store) => ({
                  value: store.id,
                  label:
                    `[${getStoreTypeName(store.backendType)}] ` +
                    (store.showName || `#${store.id}`) +
                    ` - ${store.url === null ? "No Store URL" : store.url}`,
                })),
              ]}
            />
            {/* <RenderModalCreateStore is_mobile={is_mobile} reloadListStore={listStoreToImportOrder} /> */}
            <GMButton
              type="button"
              color="SecondaryGray"
              className="altbtn"
              onClick={() => history.push('/stores')}
            >
              <Plus />Add New Store
            </GMButton>
          </div>
        </div>
        <div className="form-group mb-3">
          <label className="col-form-label font-weight-bold">
            Order type {" "}
          </label>
          <Tooltip title={profile.allowLabelOrder === 0 ? "Label order is not available" : ""}>
            <div className="input-grp">
                <Field
                  name="orderType"
                  component={renderRadioAnt}
                  defaultValue={1}
                  options={[
                    {

                      label: 'Manual order',
                      value: '1'
                    },
                    {
                      label: 'Label order (Poshmark, eBay, Etsy, TikTok)',
                      value: '2',
                      disabled: (profile.allowLabelOrder === 0 || LabelUrl === 1) ? true : false
                    },
                  ]}
                />
            </div>
          </Tooltip>
        </div>
        {orderType !== '2' ? (
          <div className="form-group mb-3">
            <label className="col-form-label font-weight-bold">
              File type {" "}
            </label>
            <div className="input-grp">
              <Field
                name="typeFile"
                setListFile={setListFile}
                is_mobile={is_mobile}
                component={renderSelectAnt}
                options={getTypeList()}
              />
              <GMButton
                type="button"
                color="SecondaryGray"
                className="altbtn"
                onClick={downloadSample}
                disabled={typeFile === -1}
              >
                <UploadIcon style={{ fill: GRAY_600, stroke: 'none' }} />Download sample
              </GMButton>
            </div>
          </div>
        ) : (
          <div className="form-group mb-3">
            <div className="input-grp">
              <GMButton
                type="button"
                color="SecondaryGray"
                className="altbtn"
                onClick={downloadSample}
                disabled={typeFile === -1}
              >
                <UploadIcon style={{ fill: GRAY_600, stroke: 'none' }} />Download sample
              </GMButton>
            </div>
          </div>
        )}
        <div className="form-group mb-3">
          <label className="col-form-label font-weight-bold">
            Uploaded Files
          </label>
          <div className="import-section">
          </div>
          <Field
            name="fileToImport"
            uploadFileRef={uploadFileRef}
            multiple={true}
            accept={listValidImportFile.join(",")}
            component={renderImportFile}
            url={`${WS_API}/orders/import-order`}
            paramName={"importFile"}
            listFile={listFile}
            setListFile={setListFile}
            uploadProgress={uploadProgress}
            setUploadProgress={setUploadProgress}
            data={{
              storeId: storeId || 0,
              typeFile: typeFile || "0",
            }}
          />
        </div>
        <div className="form-group mt-4 mb-3">
          <GMButton type="submit" disabled={isUploading} size="sm">
            Import order
          </GMButton>
        </div>
      </form >
    </div >
  );
};
const selector = formValueSelector("FormImportOrder");
const mapStateToProps = (state) => ({
  storeId: selector(state, "storeId"),
  typeFile: selector(state, "typeFile"),
  orderType: selector(state, "orderType"),
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "FormImportOrder",
    enableReinitialize: true,
    validate,
  })(Index)
);
